<template>
    <div id="auth">
        <div id="auth-result">授权结果:</div>
        <div id="auth-state">
            state : {{state}}
        </div>
        <div id="auth-auth_code">
            auth_code : {{auth_code}}
        </div>

    </div>
</template>

<script>
export default {
    name: 'AuthView',
    components: {},
    data() {
        return {
            state: "",
            auth_code: '',
        }
    },

    created() {
        this.state = this.$route.query.state;
        this.auth_code = this.$route.query.auth_code;
    }
}
</script>

<style scoped>
</style>